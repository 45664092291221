<template>
  <div class="public">
    <div class="div-children">
      <div class="p-grid p-ai-center vertical-container">
        <div class="p-col-10 p-offset-1">
          <div class="box" id="div-aging-banner"></div>
        </div>
      </div>
      <router-view />
    </div>
  </div>
  <Toast position="top-center" />
</template>
<script>
export default {
  name: 'Public'
}
</script>
<style lang="scss" scoped>
.public {
  height: 98vh;
}
.div-children {
  background-image: url('../assets/images/aging-homescreen-layout.jpg');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#div-aging-banner {
  background-image: url('../assets/images/aging-banner.png');
  height: 130px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
</style>